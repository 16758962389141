import { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { GenderEnum } from '../../../@types/graphql';
import TextInput from '../../../components/text-input/TextInput';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { identityPanelValidationSchema } from './panels.validation';
import { GenderTranslations } from 'i18n/gender.translations';
import SelectInput from '../../../components/select-input/SelectInput';
import { IdentityPanelTranslations } from './panels.translations';
import formikError from '../../../components/formik-helper/formik.helper';
import { CommonTranslations } from 'i18n/common.translations';
import { UserContext } from 'contexts/user/UserContext';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import AvatarInput from 'components/avatar-input/AvatarInput';
import moment from 'moment';

type FormikValues = {
  avatar?: File;
  gender?: GenderEnum;
  firstName: string;
  lastName: string;
  birthdate: string;
};

export default function IdentityPanel() {
  const intl = useIntl();

  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();

  const networkState = useFormikNetworkState();

  const formik = useFormik<FormikValues>({
    initialValues: {
      gender: user?.gender || undefined,
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      birthdate: user?.birthdate || '',
    },
    validationSchema: identityPanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ avatar, gender, firstName, lastName }) => {
      networkState.loading();
      const response = await updateUser({
        avatar,
        gender,
        firstName,
        lastName,
      });
      response.errors ? networkState.error() : networkState.succeeded();
    },
  });

  useEffect(() => {
    (async function () {
      if (user?.avatars.url && !formik.values.avatar) {
        const blob = await fetch(user.avatars.url).then((r) => r.blob());
        formik.setFieldValue('avatar', new File([blob], 'avatar.jpg', { type: 'image/jpeg' }));
      }
    })();
  }, [user?.avatars, formik.values.avatar]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <AvatarInput
        initialImage={formik.values.avatar}
        onChange={(file) => formik.setFieldValue('avatar', file)}
        small
      />

      <SelectInput
        name="gender"
        label={intl.formatMessage(IdentityPanelTranslations.gender)}
        initialValue={
          formik.values.gender && {
            value: formik.values.gender,
            translation: intl.formatMessage(GenderTranslations[formik.values.gender]),
          }
        }
        values={Object.values(GenderEnum).map((gender) => ({
          value: gender,
          translation: intl.formatMessage(GenderTranslations[gender]),
        }))}
        onChange={(gender) => formik.setFieldValue('gender', gender)}
        placeholder={intl.formatMessage(IdentityPanelTranslations.genderPlaceholder)}
        error={formikError(formik.touched, formik.errors, 'gender')}
        dataCy='gender'
      />

      <TextInput
        name="firstName"
        label={intl.formatMessage(IdentityPanelTranslations.firstName)}
        placeholder={intl.formatMessage(IdentityPanelTranslations.firstNamePlaceholder)}
        value={formik.values.firstName}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'firstName')}
      />

      <TextInput
        name="lastName"
        label={intl.formatMessage(IdentityPanelTranslations.lastName)}
        placeholder={intl.formatMessage(IdentityPanelTranslations.lastNamePlaceholder)}
        value={formik.values.lastName}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'lastName')}
      />

      <TextInput
        label={intl.formatMessage(IdentityPanelTranslations.birthdate)}
        name="birthdate"
        type="date"
        value={formik.values.birthdate}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'birthdate')}
        disabled
      />

      {user?.tutorEmailConfirmedAt && (
        <p
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(IdentityPanelTranslations.tutorEmailConfirmed, {
              tutorEmail: user?.tutorEmail,
              at: moment(user?.tutorEmailConfirmedAt).format('L'),
            }),
          }}
        />
      )}

      <footer>
        <PrimaryButton
          label={intl.formatMessage(CommonTranslations.save)}
          icon={networkState.iconBasedOnNetworkState}
          submit
          accent
          leftIcon
          state={networkState.state}
          disabled={!formik.dirty}
        />
        <SecondaryButton
          label={intl.formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={() => formik.resetForm()}
          iconButtonWithText
        />
      </footer>
    </form>
  );
}
